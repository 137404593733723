body * {
  font-size: .85rem;
  font-family: 'Inter', sans-serif !important;
}

body {
  background-color: #fff;
  padding-top: 10px;
}

#root {
  border-radius: 8px;
  max-width: 800px;
  margin: auto;
  background-color: #fff;
  padding-bottom: 15px;
}

.flex-column-fluid {
  flex: 1 0 auto;
}

.flex-row-fluid {
  flex: 0 1 auto;
}

.h-100 {
  height: 100% !important;
}

.flex-root {
  flex: 1;
}

.pincode-input-text {
  color: #7a7a7a;
  background-color: #f7f7f7 !important;
  font-size: 22px;
  border-radius: 8px;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.modal-title {
  width: 100%;
}

.mmenu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.mmenu li {
  font-size: 15px;
  padding: 8px 5px 8px 15px;
  background: rgb(223 223 223);
  border-radius: 0;
  margin-bottom: 5px;
  cursor: pointer;
}

.mmenu li:hover {
  background: #678add;
  color: #fff;
}

.form-control {
  border-radius: 0;
}

.form-control:focus {
  outline: none;
  box-shadow: none;
}

.header {
  padding: 8px 0 15px;
}

.store-radioButton {
  margin-bottom: 3px;
  margin-right: 3px;
  position: relative;
}

.store-radioButton input {
  display: none;
  z-index: 100;
}

.store-radioButton label {
  display: inline-block;
  background: rgb(53, 53, 53);
  color: #fff;
  padding: 5px 8px;
  border-radius: 6px;
  z-index: 90;
}

.store-radioButton input[type="radio"]:checked+label {
  background: rgb(143, 54, 2);
}

.btn-secondary {
  background: #353535 !important;
  border: none;
  padding: 10px 15px;
  border-radius: 0;
  font-size: 14px;
  cursor: pointer;
}

button{
  cursor: pointer;
}

.easy-edit-component-wrapper input {
  width: 100%;
  outline: none;
  padding: 0 5px;
  margin: 0;
  border: none;
  background-color: transparent;
}

button.easy-edit-button {
  padding: 0;
  margin: 0 3px;
  background: transparent;
}

table.hover-table tr:hover td {
  background-color: rgb(255, 255, 231);
}

.form-label {
  margin-bottom: 0.3rem;
}

.mb-3 {
  margin-bottom: 0.55rem !important;
}

.modal-title>h5 {
  margin-bottom: 0;
}

.easy-edit-inline-wrapper {
  height: 0;
}

td.text-end .easy-edit-component-wrapper input {
  text-align: right;
  padding-right: 0;
}

.table > :not(caption) > * > * {
  padding: 0.3rem 0.2rem;
}

.modal-footer {
  padding: 0;
}

@media only screen and (max-width: 768px) {
  body {
    padding-top: 0;
  }

  #root {
    border-radius: 0;
  }
}